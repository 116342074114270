import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Partner from '../components/Partner';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import { useScrollToTop } from '../hooks/useScrollToTop';



const aviso_data = [

   {
      nombre: 'Manual para la solicitud de información',
      link: 'http://jocotepec.gob.mx/wp-content/uploads/2024/12/MANUAL-DIF-PARA-LA-SOLICITUD-DE-INFORMACION.pdf'
   },
   {
      nombre: 'Formato de solicitud de información',
      link: 'http://jocotepec.gob.mx/wp-content/uploads/2024/12/SOLICITUD-DIF-DE-INFORMACION-FORMATO-2024-2027.pdf'
   },
];

const Solicitud = () => {
   useScrollToTop();

   return (
      <div className='body_wrapper'>
         <CustomNavbar
            mClass='menu_tracking'
            nClass='mr-auto'
            hbtnClass='tracking_btn'
         />
         <Breadcrumb
            breadcrumbClass='breadcrumb_area'
            imgName='breadcrumb/banner_bg.png'
            Ptitle='Solicitud de información'
            Pdescription='OPD Sistema DIF Jocotepec'
         />

         <div className='container process_area  sec_pad pl-30 pr-30 mt_90 mb_90'>
            <table >
            {aviso_data.map((item) => {
                  return (
                     <tr>        
                        <td className='text-left'>{item.nombre}</td>
                        <td><a href= {item.link} target="_blank" ><img  src={require("../img/pdf.png")}  alt="pdf-icon" width="40" height="40" /></a></td>
                     </tr>
                  );
               })}
            </table>
         </div>

      <div>

</div>

         <div className='container process_area  sec_pad pl-30 pr-30 mt_90'>
            <div className='features_info text-center responsivomovil'>
               <div>
                  <h3>Dudas o Aclaraciones</h3>
               </div>
               <div>
                  <p>Unidad de Transparencia DIF Jocotepec</p>
                  <ul className='text-center'>
                     <li>
                        <a href='tel:3877632799'>Teléfono: 387 763 2799</a>
                     </li>
                     <li>
                        <p>
                           Domicilio: Calle Hidalgo Sur #6 Colonia Centro
                           Jocotepec, Jalisco
                        </p>
                     </li>
                  </ul>
               </div>
            </div>
         </div>

         <div className='row'>
            <div className='container text-center pt_160 sec_pad_b'>
               <h3>Mapa del centro de atención:</h3>
               <iframe
                  src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1871.1728314798916!2d-103.42925411196433!3d20.285954664046262!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x50412c7454715305!2sDif%20Municipio%20de%20Jocotepec!5e0!3m2!1ses!2smx!4v1654661346434!5m2!1ses!2smx'
                  width='600'
                  height='450'
               ></iframe>
            </div>
         </div>
         <Partner pClass='partner_logo_area_two' pClasst='pt-0 mb-0' />
         {/**esta es la parte de los logos de la dependencia */}
         {/*<ServiceSubscribe sClass="s_form_info_two"/>esta es la parte del newssletter */}
         <FooterTwo fClass='pt_150' FooterData={FooterData} />
         {/**esta es la parte footer */}
      </div>
   );
};
export default Solicitud;
