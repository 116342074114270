import React from 'react'
import CustomNavbar from '../components/CustomNavbar'
import FooterTwo from '../components/Footer/FooterTwo'
import Partner from '../components/Partner'
import FooterData from '../components/Footer/FooterData'
import BreadcrumbServ from '../components/BreadcrumbServ'

import Img from '../img/des-escolares.png'


import '../assets/talleres.css'



const alimentacion = () => {
   return (
      <div className='body_wrapper'>
         <CustomNavbar
            mClass='menu_tracking'
            nClass='mr-auto'
            hbtnClass='tracking_btn'
         />
         {/* ESTA ES LA PARTE DEL MENU DEL HOME */}
         <BreadcrumbServ
            breadcrumbClass='breadcrumb_area'
            imgName='breadcrumb/banner_bg.png'
            Ptitle='Alimentación'
            Pdescription=''
            imgServ='new-home/des-escolares-sf.png'
         />

         <div className='container process_area  sec_pad pl-30 pr-30 mt_90 mb_20'>
            <div className='features_info text-center responsivomovil'>
               <div>
                  <h3>Programa De Alimentación Escolar</h3>
               </div>
               <div>
                  <p className='text-justify'>  
                  El programa está dirigido a niñas, niños y adolescentes de educación básica, proporcionándoles un alimento diario durante las horas de clases, que consiste en un desayuno frío o caliente (este último preparado por las mismas mamás de los niños, dentro del plantel).
                  </p>
               </div>
            </div>
         </div>
         <div className='container process_area  sec_pad pl-30 pr-30 mt_90 mb_20'>
            <div className='features_info text-center responsivomovil'>
               <div>
                  <h3>OBJETIVO</h3>
               </div>
               <div>
                  <p className='text-justify'> 
                     Favorecer el acceso y consumo de alimentos nutritivos e inocuos de la población de atención prioritaria, 
                     que asiste a planteles públicos del Sistema Educativo Nacional, mediante la entrega de desayunos 
                     escolares diseñados con base en criterios de calidad nutricia, acompañados de acciones de orientación y 
                     educación alimentaria, así como de aseguramiento de la calidad, para favorecer un estado de nutrición adecuado.
                  </p>
               </div>
            </div>
         </div>

         <div className='container process_area sec_pad pl-30 pr-30 mt_20 mb_20'>
               <div className='features_info text-center responsivomovil'>
                  <h3>Criterios de elegibilidad:</h3>
                  1.	Niñas, niños y adolescentes en condiciones de atención prioritaria (preferentemente con desnutrición, 
                  sobrepeso y obesidad) que asisten a planteles escolares (públicos) oficiales del sistema educativo estatal 
                  (preescolar, primaria y secundaria).
                  <br></br>
                  2.	Preferentemente, que radiquen en zona Wixárika, rurales y urbanas marginadas del estado de Jalisco.
                  <br></br>
                  3.	Que el plantel escolar se encuentre ubicado en las localidades atendidas con alta y muy alta marginación, de acuerdo al CONAPO.
               </div>
            </div>

            <div className='container process_area sec_pad pl-30 pr-30 mt_20 mb_20'>
               <div className='features_info text-center responsivomovil'>
                  <h3>Requisitos:</h3>
                  ● Registrarse en el Padrón del Programa al inicio del ciclo con la información llenada en el “Formato de Inscripción de Beneficiarios” * 
                  (el cual contiene clave del centro educativo, dirección del plantel escolar, localidad, así como datos generales del beneficiario, tales como identificación, 
                  domicilio, localidad, peso, talla, etc.).
                  <br></br>
                  ● Contar con CURP del beneficiario.
                  <br></br>
                  ● Contar con CURP del responsable o tutor.
               </div>
            </div>

            <div className='container process_area  sec_pad pl-30 pr-30 mt_90 mb_20'>
            <div className='features_info text-center responsivomovil'>
               <img src={Img} alt='desarrolloComunitario' />
            </div>
         </div>

         <div className='row'>
            <div className='container text-center pt_120 sec_pad_b'>
               <h3>MAPA DEL CENTRO DE ATENCIÓN:</h3>
               <iframe
                  title='Mapa'
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3742.3369660319595!2d-103.43261758507965!3d20.28631488640522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842f5bf570930269%3A0x1ece9fa45e10c2dc!2sC.%20Hidalgo%20Eje%20Sur%206%2C%20Jocotepec%20Centro%2C%2045800%20Jocotepec%2C%20Jal.!5e0!3m2!1ses!2smx!4v1652996368960!5m2!1ses!2smx'
                  width='600'
                  height='450'
               ></iframe>
            </div>
         </div>

         <div className='container process_area  sec_pad pl-30 pr-30 mt_20 mb_90'>
            <div className='features_info text-center responsivomovil'>
               <h3>DATOS DE CONTACTO:</h3>
               <h2>Dirección:</h2>
               <p>Calle Hidalgo #6 Centro, Jocotepec</p>
               <h2>Horario:</h2>
               <p>Lunes a Viernes de 9:00 a 15:00 horas</p>
               <h2>Teléfono:</h2>
               <a href='tel:3877632799'>387 763 27 99</a>
            </div>
         </div>

         <Partner pClass='partner_logo_area_two' pClasst='pt-0 mb-0' />
         {/**esta es la parte de los logos de la dependencia */}
         <FooterTwo fClass='pt_150' FooterData={FooterData} />
         {/**esta es la parte footer */}
      </div>
   )
}
export default alimentacion
